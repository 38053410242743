small,
.small {
	font-size: $font-size-sm;
}

em, i,
.em, .i {
	font-style: italic;
	font-synthesis: none;
}

//abbr {}
//cite {}
//em
//i
//sub
//sup

.lst {
	letter-spacing: -0.0525em;
}

.lsr {
	letter-spacing: 0;
}

.lss {
	letter-spacing: 0.0525em;
}