	.main > picture {
		margin-bottom: 15px;
	}

		.img-thumbnail {
			padding: 5px;
			border: 1px solid #ccc;
			background-color: #fff;
		}

		.img-right {
			@include media-breakpoint-up(md) {
				clear: both;
				float: right;
				width: 45%;
				margin: 0 0 15px 15px;
			}
		}

		.img-left {
			@include media-breakpoint-up(md) {
				clear: both;
				float: left;
				width: 45%;
				margin: 0 15px 15px 0;
			}		
		}

