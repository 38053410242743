.block_front {
	padding: 30px 15px;
	.wrap > :first-child { margin-top: 0; }
	.wrap > :last-child { margin-bottom: 0; }
}

.block_front .block_title {
	border-bottom: 1px solid #cccccc;
	margin-bottom: 30px;
	padding-bottom: 30px;
}