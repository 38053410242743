.nav {
	@extend %list;
	@extend %list_unstyled;
	margin-bottom: 0;
}

	.nav-item {
		box-sizing: border-box;
		list-style-type: none;
		&.active .nav-link {
			cursor: default;
			&:hover { cursor: default; }
		}
		&.open {
			> .dropdown-nav {
				display: block;
				height: auto;
			}
			.caret {
				transform: rotate(180deg);
			}
		}
	}

		.nav-link {
			transition: all 400ms;
			display: block;
			line-height: 1; 
			text-decoration: none;
			* { vertical-align: middle; }
			&:hover,
			&:focus {
				transition: all 400ms;
				outline: none;
				.caret {
					transition: all 300ms;
				}
				.caret.push {
					display: inline-block;
					width: 12px;
					margin-left: 0;
				}
			}
		}

		.dropdown-toggle {
			white-space: nowrap;
			vertical-align: middle;
		}

			.caret {
				display: inline-block;
				position: relative;
				vertical-align: baseline;
				width: 12px;
				height: 12px;
				margin-left: 5px;
			}

				.caret_push {
					transition: all 300ms;
					margin: -4px 5px 0 0;
					width: 12px;
					height: 12px;
				}

		.dropdown-nav {
			@extend %list;
			@extend %list_unstyled;
			transition: all ease-in-out 300ms;
			display: none;
			position: relative;
			z-index: 10;
			margin: 0;
			.nav-item {
				list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
			}
		}

	
/* .nav-list {

	.nav-link {
		padding: 15px;
		color: $color-text;
		&:hover {
			background-color: $color-hover;
			color: white;
		}
	}

	.nav-item:not(:last-child) {
		border-bottom: 1px solid rgba(black,0.15);
	}

	.nav-item.active > .nav-link {
		background-color: transparent !important;
		color: $color-hover !important;
		font-weight: $body-weight-bolder;
	}

} */