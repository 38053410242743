.form {
	font-family: $font-family-sans-serif;
	line-height: 1;
}

.form_section {
	overflow: hidden;
	margin: 0 0 30px;
	padding: 20px;
	border: 1px solid #ccc;

	p { line-height: 1.4; }

	.section_title {
		padding: 5px 45px 5px 10px;	
		@include font-size(18);
		font-weight: $body-weight-bolder;
		text-transform: uppercase;
		letter-spacing: -0.0475em;
	}

	.section-subtitle {
		margin: 10px 0;
		@include font-size(16);
		font-weight: $body-weight-bold;
		font-family: $font-family-body;
		text-transform: none;
		letter-spacing: -0.0475em;
		color: $color-text;
	}

	hr {
		margin: 15px 0 20px;
		background-color: #c5c5c5;
	}

}

	.form-title {
		margin: 0 0 15px;
		font-weight: $heading-weight-bolder;
		span {
			font-size: 85%;
			font-weight: $heading-weight-normal;
			letter-spacing: 0.0575em;
		}
	}


	.form_group {
		position: relative;
		&:not(:last-child) { margin: 0 0 10px; }
		&.form_hp { display: none; }
		&.form_clear { clear: both; }
	}

		label {
			display: block;
			margin: 0 0 5px;
			font-weight: $body-weight-bold;
			line-height: 1em;
		}

		.form_control {
			display: block;
			width: 100%;
			padding: 8px;
			@include font-size(18);
			font-family: $font-family-sans-serif;
			line-height: 1;
			border: 1px solid #ccc;
			background-color: $white;
			outline: none;
			&:focus {
				border-color: $blue;
				background-color: lighten($blue, 45%);
			}	
		}

			textarea.form_control {
				min-height: 100px;
				line-height: 1.275em;
			}

	.form_error .form_control {
		border-radius: 2px 2px 0 0;
		border-color: $color-danger !important;
	}

		.error-message {
			margin: -5px 0 0;
			padding: 6px 4px 4px;
			font-size: 0.7em;
			font-weight: $body-weight-bolder;
			line-height: 1em;
			text-transform: uppercase;
			background-color: theme(danger, base);
			color: $white;
		}


.form_schedule,
.form_career {
	textarea.form_control {
		min-height: 150px;
	}
	@include media-breakpoint-up(md) {
		.flex_wrapper {
			@include make-flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			.form_group {
				width: 100%;
				margin: 10px 0 0 !important;
				&.form_name,
				&.form_phone,
				&.address_street { margin-bottom: 15px; }
			}
			.left,
			.right,
			.address_city { width: 49%; }
	
			.address_state,
			.address_zipcode {
				width: 23.5%;
			}
		}
	}
}


.form_career {
	/* @include media-breakpoint-up(md) {
		.flex_wrapper {
			.form_group {
				&.form_fname,
				&.form_lname { margin-bottom: 15px; }
				&.form_phone { margin-bottom: 0; }
			}
		}
	} */
}