h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
	margin: 15px 0;
	font-family: $font-family-headings;
	font-weight: 500;
	font-stretch: 75%;
	line-height: 1;
	letter-spacing: -0.03em;
	small {
		display: block;
		font-size: 85%;
		font-weight: 400;
	}
}

h1, .h1 {
	@include font-size(28);
	font-weight: 800;
	@include media-breakpoint-up(lg) {
		@include font-size(42);
	}
}

h2, .h2 {
	@include font-size(26);
	@include media-breakpoint-up(lg) {
		@include font-size(33);
		letter-spacing: -0.015em;
	}
}

h3, .h3 {
	@include font-size(21);
	font-weight: 650;
	@include media-breakpoint-up(lg) {
		@include font-size(27);
	}
}

h4, .h4 {
	@include font-size(20);
	@include media-breakpoint-up(lg) {
		@include font-size(25);
	}
}

h5, .h5 {
	letter-spacing: 0.2em;
	@include font-size(20);
	&::before {
		display: inline-block;
		position: relative;
		vertical-align: top;
		content: "▶ ";
		transform: scale(0.8);
		margin-right: 3px;
	}
}

h6, .h6 {
	margin: 0 0 $global_mb;
	@include font-size($font-size-base);
	font-family: $font-family-body;
	font-weight: $body-weight-normal;
	@include media-breakpoint-up(lg) {
		@include font-size($font-size-base + 2);
	}
}