@mixin btn($padding: 10px 20px, $radius: 2px) {

	display: inline-block;
	padding: $padding;
	border: none;
	border-radius: $radius;
	font-family: $font-family-sans-serif;
	text-decoration: none;

	&:hover {
		cursor: pointer;
	}

}

	@mixin btn-solid($bg: $color-link, $to: $color-hover, $text: white, $shadow: false) {
		background-color: $bg;
		background-image: radial-gradient(
			circle at 50%,
			$to 50%,
			transparent 0
		);
		background-size: 0;
		background-position: 50%;
		background-repeat: no-repeat;
		color: $text;
		transition: all 300ms;

		@if $shadow == true { text-shadow: 0 1px 2px rgba(black, 0.8); }

		&:hover,
		&:focus {
			transition: all 300ms;
			border-color: lighten($to, 15%);
			background-color: $bg;
			background-size: 250%;
			color: $text;
		}
	}

/* 	@mixin btn-ghost($bg: $color-link, $color: $white) {

	} */