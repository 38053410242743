.front_welcome {

	.row+.row { 
		margin-top: 15px; 
		padding-top: 15px;
		//border-top: 1px solid #cccccc;
	}

	@include media-breakpoint-down(sm) {
		.wrap+.wrap { margin-top: 15px; }
	}

	@include media-breakpoint-up(md) {
		/* .row+.row {
			margin-top: 30px;
			padding-top: 30px;
		} */
		.row { align-items: center; }
		.welcome-top {
			align-items: center;
			flex-wrap: nowrap;
			.wrap { width: auto; }
		}
		.welcome_media { order: 4; @include make-col(1,2); }
		.welcome_content { order: 3; @include make-col(1,2); }
	}

	@include media-breakpoint-up(lg) {
		.welcome_lead {
			margin-left: 45px;
			padding: 15px 45px;
			border-left: 1px solid #ccc;
		}
	}
}


	.welcome-title {
		white-space: nowrap;
	}

	.welcome-list {
		@include list;
		@include list-unstyled;
		font-weight: 600;
		text-transform: uppercase;
		li:not(:last-child) { margin-bottom: 5px; }
		span {
			display: inline-block;
			padding: 10px;
			@include font-size(15);
			line-height: 1;
			background-color: $brand-primary-dark;
			color: white;
		}
	}

	.welcome-note {
		line-height: 1.1;
	}