html {
	position: relative;
	min-height: 100%;
	box-sizing: border-box;
	-ms-overflow-style: scrollbar;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	margin: 0;
	@include antialias;
	@include font-size($font-size-base);
	font-weight: $body-weight-normal;
	font-family: $font-family-body;
	line-height: $base-line-height;
	letter-spacing: 0.01em;
	word-spacing: -0.01em;
	-webkit-text-size-adjust: 100%;
	font-optical-sizing: auto;
	background-color: $body-bg;
	color: $color-text;
	box-sizing: border-box;
	background-image: linear-gradient(112.5deg, rgba(232, 232, 232, 0.03) 0%, rgba(232, 232, 232, 0.03) 2%,rgba(231, 231, 231, 0.03) 2%, rgba(231, 231, 231, 0.03) 4%,rgba(231, 231, 231, 0.03) 4%, rgba(231, 231, 231, 0.03) 11%,rgba(2, 2, 2, 0.03) 11%, rgba(2, 2, 2, 0.03) 67%,rgba(231, 231, 231, 0.03) 67%, rgba(231, 231, 231, 0.03) 90%,rgba(111, 111, 111, 0.03) 90%, rgba(111, 111, 111, 0.03) 100%),linear-gradient(157.5deg, rgba(210, 210, 210, 0.03) 0%, rgba(210, 210, 210, 0.03) 17%,rgba(254, 254, 254, 0.03) 17%, rgba(254, 254, 254, 0.03) 18%,rgba(96, 96, 96, 0.03) 18%, rgba(96, 96, 96, 0.03) 44%,rgba(159, 159, 159, 0.03) 44%, rgba(159, 159, 159, 0.03) 70%,rgba(24, 24, 24, 0.03) 70%, rgba(24, 24, 24, 0.03) 82%,rgba(16, 16, 16, 0.03) 82%, rgba(16, 16, 16, 0.03) 100%),linear-gradient(22.5deg, rgba(47, 47, 47, 0.03) 0%, rgba(47, 47, 47, 0.03) 32%,rgba(124, 124, 124, 0.03) 32%, rgba(124, 124, 124, 0.03) 40%,rgba(200, 200, 200, 0.03) 40%, rgba(200, 200, 200, 0.03) 42%,rgba(16, 16, 16, 0.03) 42%, rgba(16, 16, 16, 0.03) 64%,rgba(243, 243, 243, 0.03) 64%, rgba(243, 243, 243, 0.03) 94%,rgba(93, 93, 93, 0.03) 94%, rgba(93, 93, 93, 0.03) 100%),linear-gradient(90deg, #ffffff,#ffffff);
	background-attachment: fixed;	
	@include media-breakpoint-up(lg) {
		@include font-size($font-size-base + 2);
	}
}

.block {
	padding: 30px 15px;
}

.container {
	@include make-container();
	@include make-container-max-widths();
	&_fluid {
		@include make-container();
		@include make-container-max-widths();
		@include media-breakpoint-down(md) {
			max-width: none;
			padding: 0;
			.row { margin: 0; }
		}
	}
}

.row {
	@include make-row();
}

.wrap {
	@include make-col-ready();
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.has-icon {
	vertical-align: middle;
	> * { vertical-align: middle; }
}

.icon-group {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	line-height: 1;
	.item-icon { margin-right: 5px; }
}

.icon {
	display: inline-block;
	line-height: 0;
	vertical-align: middle;
}

.icon-stars { fill: $color-review; }

strong, .strong {
	font-weight: $body-weight-bolder;
}

b, .b { 
	font-weight: $body-weight-bold; 
}

picture {
	display: inline-block;
	max-width: 100%;
	width: auto;
	height: auto;
	border-style: none;
	line-height: 0;
	img {
		display: block;
		width: 100%;
		height: auto;
	}
}

a {
	font-style: normal;
	text-decoration: underline;
	color: $color-link;
	&:hover,
	&:focus {
		color: $color-hover;
		text-decoration: none;
	}
	&.open:focus { outline: none; }
	&.phone {
		text-decoration: none;
		white-space: nowrap;
		cursor: default !important;
	}
	&.mask {
		text-decoration: none;
		color: inherit;
		&:hover { color: inherit; }
	}
}