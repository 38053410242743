// Clearfix
@mixin cf {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin make-flex {
	display: flex;
	flex-wrap: wrap;
}

// FIX or SET MARGINS
@mixin margin--ends($top: 0, $bottom: 0) {
	margin: { top: $top; bottom: $bottom; }
}

@mixin margin--sides($left: 0, $right: 0) {
	margin: { left: $left; right: $right; }
}

@mixin margin--auto {
	margin: { left: auto; right: auto; }
}

@mixin centered-block {
	display: block;
	margin: { left: auto; right: auto; }
}

// REMOVE TOP AND BOTTOM MARGINS FROM FIRST AND LAST CHILDREN
@mixin content-margins($selector: '> *', $last-child: false) {
	@if not $selector {
		$selector: '&';
	}

	#{unquote($selector)} {
		&:first-child {
			margin-top: 0;
		}

		@if $last-child {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

// ALIGN ANYTHING VERTICALLY OR HORIZONTALLY WITH JUST 3 LINES: 1) postion, 2) top/left, 3) tranlate
@mixin align($vertical: true, $horizontal: false, $position: relative) {
	@if $position { position: $position; }
	@if $vertical { top: 50%; }
	@if $horizontal { left: 50%; }
	@if $vertical and $horizontal { transform: translateX(-50%) translateY(-50%); }
	@else if $vertical { transform: translateY(-50%); }
	@else { transform: translateX(-50%); }
}

@mixin hardware($backface: true, $perspective: 1000) {
	@if $backface { backface-visibility: hidden; }
	perspective: $perspective;
}

@mixin pos($pos, $t, $r, $b, $l, $z: false, $hardware: true) {
	@if $pos==fixed and $hardware { @include hardware; }
	@if $pos { position: $pos; }
	@if $t { top: $t; }
	@if $r { right: $r; }
	@if $b { bottom: $b; }
	@if $l { left: $l; }
	@if $z { z-index: $z; }
}