$theme: (
	primary: (
		base: $brand-primary,
		light: lighten($brand-primary, $color-lighten),
		dark: darken($brand-primary, $color-darken),
		opac: transparentize($brand-primary, $color-opac),
		sat: saturate($brand-primary, $color-sat),
		desat: desaturate($brand-primary, $color-desat)
	),
	secondary: (
		base: $brand-secondary,
		light: lighten($brand-secondary, $color-lighten),
		dark: darken($brand-secondary, $color-darken),
		opac: transparentize($brand-secondary, $color-opac),
		sat: saturate($brand-secondary, $color-sat),
		desat: desaturate($brand-secondary, $color-desat)
	),
	highlight: (
		base: $brand-highlight,
		light: lighten($brand-highlight, $color-lighten),
		dark: darken($brand-highlight, $color-darken),
		opac: transparentize($brand-highlight, $color-opac),
		sat: saturate($brand-highlight, $color-sat),
		desat: desaturate($brand-highlight, $color-desat)
	),
	accent: (
		base: $brand-accent,
		light: lighten($brand-accent, $color-lighten),
		dark: darken($brand-accent, $color-darken),
		opac: transparentize($brand-accent, $color-opac),
		sat: saturate($brand-accent, $color-sat),
		desat: desaturate($brand-accent, $color-desat)
	),
	review: (
		base: $color-review,
		light: lighten($color-review, $color-lighten),
		dark: darken($color-review, $color-darken),
		opac: transparentize($color-review, $color-opac),
		sat: saturate($color-review, $color-sat),
		desat: desaturate($color-review, $color-desat)
	),
	success: (
		base: $color-success,
		light: lighten($color-success, $color-lighten),
		dark: darken($color-success, $color-darken),
		opac: transparentize($color-success, $color-opac),
		sat: saturate($color-success, $color-sat),
		desat: desaturate($color-success, $color-desat)
	),
	danger: (
		base: $color-danger,
		light: lighten($color-danger, $color-lighten),
		dark: darken($color-danger, $color-darken),
		opac: transparentize($color-danger, $color-opac),
		sat: saturate($color-danger, $color-sat),
		desat: desaturate($color-danger, $color-desat)
	),
	warning: (
		base: $color-warning,
		light: lighten($color-warning, $color-lighten),
		dark: darken($color-warning, $color-darken),
		opac: transparentize($color-warning, $color-opac),
		sat: saturate($color-warning, $color-sat),
		desat: desaturate($color-warning, $color-desat)
	),
	info: (
		base: $color-info,
		light: lighten($color-info, $color-lighten),
		dark: darken($color-info, $color-darken),
		opac: transparentize($color-info, $color-opac),
		sat: saturate($color-info, $color-sat),
		desat: desaturate($color-info, $color-desat)
	)
		
);

$brand-primary-dark: theme(primary, dark);
$brand-primary-light: theme(primary, light);

$brand-secondary-dark: theme(secondary, dark);
$brand-secondary-light: theme(secondary, light);

$brand-highlight-dark: theme(highlight, dark);
$brand-highlight-light: theme(highlight, light);

$brand-accent-dark: theme(accent, dark);
$brand-accent-light: theme(accent, light);

$color-review-dark: theme(review, dark);
$color-review-light: theme(review, light);

$color-danger-dark: theme(danger, dark);
$color-danger-light: theme(danger, light);

$color-success-dark: theme(success, dark);
$color-success-light: theme(success, light);

$color-warning-dark: theme(warning, dark);
$color-warning-light: theme(warning, light);

$color-info-dark: theme(info, dark);
$color-info-light: theme(info, light);