.nav-title {
	margin: 0 0 25px;
	@include font-size(20);
	font-weight: $body-weight-bolder;
	line-height: 1;
	a {
		@include make-flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		margin-left: -15px;
		padding-left: 15px;
		color: white !important;
	}
	@include media-breakpoint-up(lg) {
		display: none;
	}
}

	.icon-close { fill: white; }

.mobile-toggle {
	display: inline-block;
	padding: 10px 12px;
	background-color: $color-danger;
	line-height: 0;
}

.site_navigation {

	&.mobileShowing { display: block; }

	display: none;
	position: absolute;
	top: 0; left: 0;
	z-index: 8;
	overflow-y: auto;
	width: 100%;
	height: 100%;
	padding: 15px 0;
	font-stretch: 75%;
	text-align: left;
	background-color: $brand-primary-dark;

	.nav-item.active > .nav-link {
		text-shadow: none;
		background: $white !important;
		color: $color-text !important;
		@include media-breakpoint-up(lg) {
			background: transparent !important;
		}
	}

	.nav-link {
		padding: 15px;
		font-weight: 680;
		letter-spacing: -0.02em;
		//text-shadow: 0 1px 1px rgba(black,0.6);
		color: white;
		&:hover {
			background-color: $brand-secondary-dark;
			color: $brand-primary-dark;
			.caret { fill: $brand-primary-dark; }
		}
	}

	.caret { fill: white; }
	
	@include media-breakpoint-down(md) {
		.nav-item {
			&:not(:last-child) { border-bottom: 1px solid black; }
			&:not(:first-child) { border-top: 1px solid rgba(white,0.1); }	
		}
		.caret_push { display: none; }
		.dropdown-nav {
			background-color: rgba(black,0.25);
		}
	}

	/* @include media-breakpoint-only(md) {
		.nav { 
			justify-content: space-between; 
			> .nav-item { 
				width: 19% !important;
				> .nav-link { 
					display: block !important;
					width: 100% !important; 
				}
			}
		}
	} */

	@include media-breakpoint-up(lg) {
		display: block;
		position: relative;
		top: unset; left: unset; right: unset;
		width: unset; height: unset;
		overflow: visible;
		padding: 15px;
		background-color: transparent;
		
		.nav {
			@include make-flex;
			flex-wrap: nowrap;
			align-items: stretch;
			line-height: 0;
		}

		.nav > .nav-item {
			width: auto;
			
			&:not(:last-child) {
				margin-right: 1%;
			}

			> .nav-link {
				@include btn(15px 15px, 4px);
				@include btn-solid($brand-primary-dark, $brand-secondary-dark, white, false);
				text-align: center;
				.caret { transition: fill 300ms; }
				&:hover, &:focus {
					.caret { 
						transition: fill 300ms;
						fill: white; 
					}
				}
			}
		}

		.nav-link {
			width: 100%;
			padding: 10px;
			line-height: 1;
			&:hover, &:focus {
				color: white;
			}
		}

		.caret_push {
			fill: $color-hover;
		}

		.dropdown-nav {
			position: absolute;
			min-width: 320px;
			z-index: 10;
			margin-top: 10px;
			padding: 15px;
			text-align: left;
			background-color: $brand-primary-dark;

			.nav-item:not(:last-child) { border-bottom: 1px solid black; }
			.nav-item:not(:first-child) { border-top: 1px solid rgba(white,0.2);}

			.nav-item.active .nav-link {
				color: white !important;
				background-color: transparent !important;
				.caret_push { margin-left: 0 !important; }
			}

			.nav-link {
				padding-left: 0;
				overflow: hidden;
				.caret_push { margin-left: -12px; }
				&:hover,
				&:focus {
					background-color: transparent;
					color: $color-hover;
					.caret_push { margin-left: 0; }
				} 
			}
		}

	}

	@include media-breakpoint-up(lg) {
		margin-top: -65px;
		.wrap { padding-right: 0; }
		.nav { justify-content: flex-end; }
	}

}