.front_jumbo {
	position: relative;

	background-color: $brand-primary-dark;
	color: white;

	.wrap { padding: 20px; }

	@include media-breakpoint-up(md) {
		padding: 0;
		background-color: transparent;
		background-image: url("/_/images/layout/3.webp");
		background-size: cover;
		background-position: right center;
		color: $color-text;

		.bg-filter {
			padding: 30px 0;
			background-color: rgba($brand-primary-dark,0.4);
			backdrop-filter: blur(5px);
		}
		
		.row { 
			justify-content: space-between; 
			align-items: center;
		}
		.wrap { @include make-col(49,100); }

	}

	@include media-breakpoint-up(lg) {
		.wrap.jumbo_conversion {
			flex: none;
			width: auto;
			min-width: 345px;
		}
	}

}

.jumbo_marketing {
	@include media-breakpoint-up(md) {
		color: white;
		@include font-size(18);
		font-weight: 700;
		text-shadow: 1px 2px 4px rgba(black,0.8);
	}
	@include media-breakpoint-up(lg) {
		@include font-size(20);
		letter-spacing: -0.02em;
	}
	@include media-breakpoint-up(xl) {
		@include font-size(23);
	}
}

	.jumbo-title {
		margin-top: 0;
		/* &::before {
			display: block;
			font-size: 80%;
			line-height: 1.3;
			//letter-spacing: 0.02em;
			font-weight: 650;
			content: "The Treasure Coast LOVES ";
		} */
	}

	.jumbo-list {
		@include list;
		@include list-spaced;
		@include list-unstyled;
		li {
			strong {
				display: inline-block;
				transform: skew(-15deg);
				padding: 3px 7px;
				background-color: $brand-primary;
				text-shadow: 0 1px 2px rgba(black,0.3);
			}
		}
	}

	.jumbo-call {
		@include font-size(24);
		font-family: $font-family-body;
		font-weight: 700;
		letter-spacing: -0.02em;
		//color: $red;
		/* .phone {
			display: inline-block;
			padding: 0 10px;
			background-color: rgba(white,0.7);
			color: $brand-primary !important;
			text-shadow: none;
		} */
		@include media-breakpoint-up(lg) {
			@include font-size(30);
		}		
	}

.jumbo_conversion {
	border-top: 1px solid white;
	@include media-breakpoint-up(md) {
		border-top: none;
		background-color: rgba(white, 0.5);
		border-radius: 8px;
	}
}