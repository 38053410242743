// =====================================================
// VARIABLES - TOC
// -----------------------------------------------------
//
// 1. Grid - Bootstrap Grid Maps
//
// 2. Layout - zindex, position, padding, margin, radius, etc.
//
// 3. Typography - Font Styles
//
// 4. Colors - Universal Colors
//
// =====================================================




// =====================================================
// 1. GRID
// =====================================================

// FROM BOOTSTRAP VARIABLES

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px);

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px);

$grid-columns: 16;
$grid-gutter-width: 30px;
$grid-row-columns: 8;

// =====================================================
// 2. LAYOUT
// =====================================================

$global_mb--sm: 5px;
$global_mb: 15px;
$global_mb--lg: 30px;



// ===========================================
// 3. TYPOGRAPHY
// ===========================================

$font-family-sans-serif: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-serif: 'Times New Roman', Georgia, Times, serif; 
$font-family-monospace: Menlo, Monaco, Consolas, 'Courier New', monospace;

$font-family-headings: $font-family-sans-serif;
$font-family-body: $font-family-sans-serif;

$font-size-base: 16;

$font-size-lg: 21;
$font-size-sm: 14;

$base-line-height: 1.4525;

$body-weight-normal: 400;
$body-weight-bold: 600;
$body-weight-bolder: 800;

$heading-weight-normal: 400;
$heading-weight-bold: 600;
$heading-weight-bolder: 800;


// =====================================================
// 4. COLORS
// =====================================================

$white:  #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:  #000 !default;

$blue:    	#1278d0 !default;
$indigo:  	#6610f2 !default;
$purple:  	#964ebb !default;
$pink:    	#e83e8c !default;
$red:     	#dc3545 !default;
$orange:  	#ff8400 !default;
$yellow:  	#ffc107 !default;
$green:   	#3dc920 !default;
$teal:    	#20c997 !default;
$cyan:    	#17a2b8 !default;
$maroon: 	#8b1b2c !default;
$gold: 		#786a4b !default;

$brand-primary: 		#2c2989;
$brand-secondary: 		#54c6ff; 
$brand-highlight: 		#ec7d1c; //#d7cc15;
$brand-accent: 			#E9DE14; 
$color-review: 			$yellow; // Black
$color-success: 		$green; //$green;
$color-danger: 			$red; // $red; //#f00;
$color-info: 			#0182C8; //$indigo;
$color-warning: 		$yellow; //$yellow;

$color-facebook:		#3a8bf4;
$color-google:			#ed5f53;
$color-instagram:		$pink;
$color-trip-advisor:	#26b782;
$color-yelp: 			#da4444;

// THEME COLOR DECLARATIONS
// -------------------------------------------

// THEME MAP - use function: theme(key, variant)
// ex: h1 { color: theme(primary, dark); }
// OR: div { background-color: theme(success, opac); }

// COLOR MODIFIES
$color-lighten: 7.5% !default;
$color-darken: 7.5% !default;
$color-opac: 0.45 !default;
$color-sat: 15% !default;
$color-desat: 15% !default;

// These variable's values should be equal to
// something listed above - a THEME COLOR VARIABLE.

$body-bg:				#f5f5f5;
$color-text:			#292929;

$color-link:			$blue;
$color-hover:			lighten($color-link, 5%);

$color-phone:			$brand-primary;