.block {
	padding: 30px 15px;
	.wrap > :first-child { margin-top: 0; }
	.wrap > :last-child { margin-bottom: 0; }
}

.page_quote {
	@include media-breakpoint-only(md) {
		.main {
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			picture {
				min-width: 360px;
				max-width: 360px;
				width: 360px;
			}
			p {
				margin-right: 30px;
				@include font-size(20);
			}
		}
	}
}

.page_career {
	@include media-breakpoint-up(lg) {
		.page_content .main { @include make-col(4,5); }
	}
	@include media-breakpoint-up(xl) {
		.page_content .main { @include make-col(3,4); }
	}
}