.site_banner {
	.wrap { 
		padding: 15px; 
		line-height: 1;
	}
	@include media-breakpoint-up(lg) {
		padding-top: 15px;
		.row { 
			flex-wrap: nowrap; 
			align-items: flex-start;
		}
		.wrap {
			flex: none;
			width: auto; 
			background-color: transparent;
		}
		.banner_rating, .banner_contact {
			padding-top: 10px;
			padding-bottom: 0;
		}
		.banner_rating { margin-left: auto; }
	}
}

.banner_branding, 
.banner_button {
	@include make-col(1,2);
}

.banner_rating,
.banner_contact {
	@include make-col(1,2);
	line-height: 1;
}

.banner_button,
.banner_contact {
	text-align: right;
}

.contact-hours b {
	@include font-size(16);
	font-stretch: 75%;
	font-weight: 700;
	letter-spacing: -0.02em;
}

.contact-phone .phone {
	@include font-size(21);
	font-weight: 800;
	letter-spacing: -0.02em;
	@include media-breakpoint-up(md) {
		@include font-size(24);
	}
}

.banner_rating {
	div * { vertical-align: middle; }
	div+div { margin-top: 5px; }
	@include font-size(16);
	font-stretch: 75%;
	font-weight: 700;
	letter-spacing: -0.02em;
}