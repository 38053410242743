.btn {
	@include btn(10px 15px, 4px);
	@include btn-solid($brand-primary-dark, $brand-secondary, white, false);
	font-size: inherit;
	font-weight: $body-weight-bolder;
	font-family: $font-family-sans-serif;
	text-shadow: 0 1px 2px rgba(black,0.4);
	text-transform: uppercase;
	transform: scale(0.95);

	&:hover {
		transform: scale(1);
		box-shadow: 0 15px 15px -15px rgba(black,0.7);
	}
	
}

.btn-review {
	@include btn-solid($orange, $brand-secondary, white, false);
}

.btn-submit {
	@include btn-solid($red, $brand-secondary, white, false);
}