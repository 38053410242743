.mobile_toggle {
	//width: 100%;
	transition: all 300ms;
	padding: 10px 12px;
	text-align: left;
	line-height: 0;
	border: none;
	border-radius: 4px;
	background-color: $brand-primary-dark;
	color: white;
	&:hover {
		transition: all 300ms;
		background-color: $brand-secondary;
		cursor: pointer;
	}
	@include media-breakpoint-up(lg) {
		display: none;
	}
}

	.icon-bars {
		fill: white;
	}



