ul, ol, li {
	padding: 0;
	margin: 0;
}

.list {
	@include list;
}

	ul.list {
		list-style-type: square;
	}

.list-unstyled {
	@include list_unstyled;
}

.list-inline {
	@include list_inline;
}

.list-spaced {
	@include list_spaced;
}

.list-bordered {
	@include list_unstyled;
	li { padding: 10px; }
	> li:nth-child(even) {
		background-color: #eeeeee;
	}
}

.list-checked {
	@include list_unstyled;
	@include list_spaced;
	li {
		display: flex;
		flex-wrap: nowrap;
		line-height: 1;
		&::before {
			line-height: 1;
			margin-right: 6px;
			color: $brand-secondary;
			content: "";
		}
	}
	&.--check li::before { content: "\2714"; } // \2611 in square
	&.--star li::before { content: "\2605"; }
	&.--diamond li::before { content: "\2666"; }
	&.--crossed li::before { content: "\2718"; }
}

.list-table {
	b {
		display: inline-block;
		width: 33%;
		padding-right: 15px;
	}
}

.list-striped {
	li {
		padding: 8px 10px; 
		line-height: 1;
	}
	li:nth-child(even) {
		background-color: white;
	}
}


.list-feedback {
	a {
		display: inline-block;
		padding: 7px 15px;
		border-radius: 50px;
		transition: all 300ms;
		transform: scale(0.95);
		border: 2px solid white;
		background-color: white;
		&:hover {
			transition: all 300ms;
			transform: scale(1);
			box-shadow: 0 15px 12px -15px rgba(black, 0.5);
		}
		&.feedback-google:hover {
			border-color: $color-google;
			color: $color-google
		}
		&.feedback-facebook:hover {
			border-color: $color-facebook;
			color: $color-facebook;
		}
		&.feedback-tripadvisor:hover {
			border-color: $color-trip-advisor;
			color: $color-trip-advisor;
		}
		&.feedback-yelp:hover {
			border-color: $color-yelp;
			color: $color-yelp;
		}
	}
}