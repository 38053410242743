%list {
	margin: 0 0 $global_mb;
	padding: 0 0 0 18px;
}

%list_unstyled {
	margin-left: 0;
	padding-left: 0;
	list-style-type: none;
	list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
}

%list_inline {
	@extend %list_unstyled;
	li { display: inline-block; }
}

%list_spaced {
	li:not(:last-child) {
		margin-bottom: 10px;
	}
}